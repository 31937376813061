import { DealerCountry } from './../entities/dealer-country';
import { Component, OnInit } from '@angular/core';
import { DealerService } from '../dealer.service';
import { MainTopImagesService } from '../main-top-images.service';

@Component({
  selector: 'app-dealers',
  templateUrl: './dealers.component.html',
  styleUrls: ['./dealers.component.scss']
})
export class DealersComponent implements OnInit {

  dealerCountries: DealerCountry[];

  constructor(private dealerService: DealerService, public mainTopImagesService: MainTopImagesService) 
  {
    mainTopImagesService.showCopyright = true;
    mainTopImagesService.showCopyrightOWM = false;

    mainTopImagesService.galleryImages = [
      {
        small: '../../assets/media/images/bezug/kostraum.jpg',
        medium: '../../assets/media/images/bezug/kostraum.jpg',
        big: '../../assets/media/images/bezug/kostraum.jpg',
      },
      {
        small: '../../assets/media/images/bezug/kr.jpg',
        medium: '../../assets/media/images/bezug/kr.jpg',
        big: '../../assets/media/images/bezug/kr.jpg'

      }
    ];
    mainTopImagesService.galleryImagesSm = [
      {
        small: '../../assets/media/images/bezug/kostraum_sm.jpg',
        medium: '../../assets/media/images/bezug/kostraum_sm.jpg',
        big: '../../assets/media/images/bezug/kostraum_sm.jpg',
      },
      {
        small: '../../assets/media/images/bezug/kr_sm.jpg',
        medium: '../../assets/media/images/bezug/kr_sm.jpg',
        big: '../../assets/media/images/bezug/kr_sm.jpg'

      }
    ];
  }
  ngOnInit() {
    this.getDealerCountries();
  }

  getDealerCountries(): void {
    this.dealerService.getDealerCountries()
      .subscribe(
        data => {
          this.dealerCountries = data;
        },
        error => {
          console.log(error);
        }
      );
  }

}
