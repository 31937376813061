import { HttpClient, HttpClientModule } from '@angular/common/http';

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MaterialModule } from './material';
import { FlexLayoutModule } from '@angular/flex-layout';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavbarComponent } from './navbar/navbar.component';
import { MainTopComponent } from './main-top/main-top.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { NgxHmCarouselModule } from 'ngx-hm-carousel';
import { WineItemComponent } from './wine-item/wine-item.component';
import { WineCarouselComponent } from './wine-carousel/wine-carousel.component';
import { FormsModule } from '@angular/forms';
import { ProductsComponent } from './products/products.component';
import { DealersComponent } from './dealers/dealers.component';
import { ContactComponent } from './contact/contact.component';
import { LegalNoticeComponent } from './legal-notice/legal-notice.component';
import { RewardComponent } from './reward/reward.component';
import { LocationsComponent } from './locations/locations.component';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxGalleryModule } from 'ngx-gallery';
import 'hammerjs';
import { WineCarouselV2Component } from './wine-carousel-v2/wine-carousel-v2.component';
import { NguCarouselModule } from '@ngu/carousel';
import { DataPrivacyComponent } from './data-privacy/data-privacy.component';


@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    MainTopComponent,
    HomeComponent,
    FooterComponent,
    WineItemComponent,
    WineCarouselComponent,
    ProductsComponent,
    DealersComponent,
    ContactComponent,
    LegalNoticeComponent,
    RewardComponent,
    LocationsComponent,
    WineCarouselV2Component,
    DataPrivacyComponent,
  ],
  entryComponents: [
  ]
  ,
  imports: [
    MaterialModule,
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    NgxHmCarouselModule,
    HttpClientModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    ReactiveFormsModule,
    NgxGalleryModule,
    NguCarouselModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
