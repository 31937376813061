import { WineService } from './../wine.service';
import { WineItem } from '../entities/wine';
import { Component, OnInit } from '@angular/core';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery';
import { MainTopImagesService } from '../main-top-images.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  wines: WineItem[];

  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];



  constructor(private wineService: WineService, public mainTopImagesService: MainTopImagesService) 
  { 

    mainTopImagesService.galleryImages = mainTopImagesService.galleryImagesStandard;
    mainTopImagesService.galleryImagesSm = mainTopImagesService.galleryImagesStandardSm;
    mainTopImagesService.showCopyrightOWM = false;
    mainTopImagesService.showCopyright = false;


  }


  ngOnInit() {
    this.mainTopImagesService.showCopyright = false;
    this.getWines(0, 9);
    this.galleryOptions = [
      {
          width: '100%',
          height: '100%',
          thumbnails: false,
          imageAnimation: NgxGalleryAnimation.Zoom,
          imageArrows: false, 
          thumbnailsArrows: false,
          imageAutoPlay: true,
          imageAutoPlayInterval: 4000,
          imageInfinityMove: true,
          preview: false,


      },
      // max-width 800
      {
          breakpoint: 800,
          imagePercent: 80,
          height: '40vh'
      },
      // max-width 400
      {
          breakpoint: 400,
          height: '40vh'
      }
  ];

  this.galleryImages = [
      {
        small: '../../assets/media/images/ws7c.jpg',
        medium: '../../assets/media/images/ws7c.jpg',
        big: '../../assets/media/images/ws7c.jpg',
        description: ''

      },
      {
        small: '../../assets/media/images/ws2c.jpg',
        medium: '../../assets/media/images/ws2c.jpg',
        big: '../../assets/media/images/ws2c.jpg',
        description: ''


      }
  ];
  }

  getWines(start: number, amount: number): void {
    this.wineService.getWinesRange(start, amount)
      .subscribe(
        data => {
          this.wines = data;
        },
        error => {
          console.log(error);
        }
      );
  }
}
