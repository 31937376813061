import { Injectable } from '@angular/core';
import { NgxGalleryImage, NgxGalleryOptions, NgxGalleryAnimation } from 'ngx-gallery';

@Injectable({
  providedIn: 'root'
})
export class MainTopImagesService {

  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];
  galleryImagesSm: NgxGalleryImage[];
  galleryImagesStandard: NgxGalleryImage[];
  galleryImagesStandardSm: NgxGalleryImage[];
  showCopyright: boolean;
  showCopyrightOWM = false;

  constructor() {
    this.showCopyright=false;
    this.galleryOptions = [
      {
        width: '100%',
        height: '100%',
        thumbnails: false,
        imageAnimation: NgxGalleryAnimation.Zoom,
        imageArrows: false,
        thumbnailsArrows: false,
        imageAutoPlay: true,
        imageAutoPlayInterval: 6000,
        imageInfinityMove: true,
        preview: false,
        imageDescription:true


      },
      // max-width 800
      {
        breakpoint: 960,
        imagePercent: 80,
      },
      // max-width 400
      {
        breakpoint: 600,
      }
    ];

    this.galleryImagesStandard = [
      {
        small: '../../assets/media/images/home/haus.jpg',
        medium: '../../assets/media/images/home/haus.jpg',
        big: '../../assets/media/images/home/haus.jpg'
      }
    ];
    this.galleryImagesStandardSm = [
      {
        small: '../../assets/media/images/home/haus_mobile.jpg',
        medium: '../../assets/media/images/home/haus_mobile.jpg',
        big: '../../assets/media/images/home/haus_mobile.jpg'
      }
    ];
    

    this.galleryImages = this.galleryImagesStandard;
    
  }
}
