import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery';
import { MainTopImagesService } from '../main-top-images.service';

@Component({
  selector: 'app-main-top',
  templateUrl: './main-top.component.html',
  styleUrls: ['./main-top.component.scss']
})
export class MainTopComponent implements OnInit {

  //@ViewChild('videoPlayer', { static: false }) videoplayer: ElementRef;

  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];


  constructor(public mainTopImagesService: MainTopImagesService) { 
    
  }

  ngOnInit() {

    this.galleryOptions = this.mainTopImagesService.galleryOptions;
    /*[
      {
          width: '100%',
          height: '100%',
          thumbnails: false,
          imageAnimation: NgxGalleryAnimation.Zoom,
          imageArrows: false, 
          thumbnailsArrows: false,
          imageAutoPlay: true,
          imageAutoPlayInterval: 4000,
          imageInfinityMove: true,
          preview: false,

      },
      // max-width 800
      {
          breakpoint: 800,
          imagePercent: 80,
          height: '40vh'
      },
      // max-width 400
      {
          breakpoint: 400,
          height: '40vh'
      }
  ];*/

  this.galleryImages = this.mainTopImagesService.galleryImages;
  /*[
      {
        small: '../../assets/media/images/ws7c.jpg',
        medium: '../../assets/media/images/ws7c.jpg',
        big: '../../assets/media/images/ws7c.jpg'
      },
      {
        small: '../../assets/media/images/ws2c.jpg',
        medium: '../../assets/media/images/ws2c.jpg',
        big: '../../assets/media/images/ws2c.jpg'

      }
  ];*/
  }
}
