import { RewardService } from './../reward.service';
import { environment } from './../../environments/environment';

import { Reward } from './../entities/reward';
import { Component, OnInit } from '@angular/core';
import { Pricer } from '../entities/pricer';
import { MainTopImagesService } from '../main-top-images.service';

@Component({
  selector: 'app-reward',
  templateUrl: './reward.component.html',
  styleUrls: ['./reward.component.scss']
})
export class RewardComponent implements OnInit {

  pricers: Pricer[];
  pricers2021: Pricer[];
  rewards: Reward[];
  apiUrl: string;

  constructor(private rewardService: RewardService, public mainTopImagesService: MainTopImagesService) {
    mainTopImagesService.galleryImages = [
      {
        small: '../../assets/media/images/salon.jpg',
        medium: '../../assets/media/images/salon.jpg',
        big: '../../assets/media/images/salon.jpg',
        description: 'Copyright fehlt hier noch'

      }];
      mainTopImagesService.galleryImagesSm = [
        {
          small: '../../assets/media/images/salon.jpg',
          medium: '../../assets/media/images/salon.jpg',
          big: '../../assets/media/images/salon.jpg',
          description: 'Copyright fehlt hier noch'

        }];
    this.apiUrl = environment.apiUrl;
    mainTopImagesService.showCopyright = false;
    mainTopImagesService.showCopyrightOWM = true;
  }

  ngOnInit() {
    this.pricers =[];
    this.pricers2021 =[];
    this.getPricers();
  }

  getRewards(): void {
    this.rewardService.getRewards()
      .subscribe(
        data => {
          this.rewards = [];
          data.forEach(element => {
            element.lines = element.text.split('\n');
            this.rewards.push(element);
            console.log(element);
          });
        },
        error => {
          console.log(error);
        }
      );
  }
  getPricers(): void {
    this.rewardService.getPricers()
      .subscribe(
        data => {
          data.forEach(p => {
            console.log(p);

            if(p.name.includes("20")) {
              this.pricers2021.push(p);
            } else {
              this.pricers.push(p);
            }
          });
        },
        error => {
          console.log(error);
        }
      );
  }


}
