import { Component, ElementRef, ViewChild, OnInit, enableProdMode } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID, APP_ID, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  //keep refs to subscriptions to be able to unsubscribe later

  // @ViewChild('maindiv', {static: false}) mainContainer: ElementRef;

  title = 'Weingut-Sigl';

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(APP_ID) private appId: string,
    private router: Router,
  ) {


    if (environment.production) {

      // HACK: Don't log to console in production environment.
      // TODO: This can be done in better way using logger service and logger factory.
      if (window) {
        window.console.log = window.console.warn = window.console.info = function () {
          // Don't log anything.
        };
      }
    }
  }


  ngOnInit() {

  /*
      let cc = window as any;
         cc.cookieconsent.initialise({
           palette: {
             popup: {
               background: "#8B8B7B",
               text: "#fff"
             },
             button: {
               background: "#fff",
               text: "#383838"
             }
           },
        onStatusChange: function (status) {
          console.log(this.hasConsented() ?
            'enable cookies' : 'disable cookies');
        },
           theme: "classic",
           content: {
             message: 'Diese Seite verwendet Cookies, um das bestmögliche Erlebnis zu bieten. Surfen Sie weiterhin auf unserer Seite, stimmen Sie unserer Cookie-Nutzung und unserer Datenschutzrichtlinie zu.',
             dismiss: 'Ja, das Ist okay!',
             link: 'Hier gibts mehr Infos!',
             href: "/impressum" 
           }
         });
        */}

  onActivate(event: any) {
    /* if (isPlatformBrowser(this.platformId)) {
       let finalPos = this.mainContainer.nativeElement.offsetTop -100;
       console.log(finalPos);
       if(this.router.url == '/home')
         finalPos = 0;  
 
     //    window.scrollTo(0, finalPos);
     window.scrollTo(0, 0);
    /*   let scrollToTop = window.setInterval(() => {
         let pos = window.pageYOffset;
         if (pos > finalPos) {
           window.scrollTo(finalPos, pos - 20); // how far to scroll on each step
         } else {
           window.clearInterval(scrollToTop);
         }
       }, 8);*/
    //  }
  }


}
