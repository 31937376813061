import { Component, OnInit } from '@angular/core';
import { MainTopImagesService } from '../main-top-images.service';

@Component({
  selector: 'app-legal-notice',
  templateUrl: './legal-notice.component.html',
  styleUrls: ['./legal-notice.component.scss']
})
export class LegalNoticeComponent implements OnInit {

  constructor(public mainTopImagesService: MainTopImagesService) { 
    mainTopImagesService.showCopyrightOWM = false;
    mainTopImagesService.showCopyright = false;
  }

  ngOnInit() {
  }

}
