import { LegalNoticeComponent } from './legal-notice/legal-notice.component';
import { RewardComponent } from './reward/reward.component';
import { LocationsComponent } from './locations/locations.component';
import { ContactComponent } from './contact/contact.component';
import { DealersComponent } from './dealers/dealers.component';
import { ProductsComponent } from './products/products.component';
import { HomeComponent } from './home/home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DataPrivacyComponent } from './data-privacy/data-privacy.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'produkte',
    component: ProductsComponent
  },
  {
    path: 'bezug',
    component: DealersComponent
  }
  ,
  {
    path: 'lagen',
    component: LocationsComponent
  }
  ,
  {
    path: 'kontakt',
    component: ContactComponent
  }
  ,
  {
    path: 'auszeichnungen',
    component: RewardComponent
  }
  ,
  {
    path: 'impressum',
    component: LegalNoticeComponent
  },
  {
    path: 'datenschutz',
    component: DataPrivacyComponent
  },
  { path: '**', redirectTo: 'home' }

];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      { enableTracing: false }
    )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
