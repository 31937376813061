import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MainTopImagesService } from '../main-top-images.service';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation, NgxGalleryComponent } from 'ngx-gallery';


@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss']
})
export class LocationsComponent implements OnInit {

  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];

  @ViewChild('previewGallery', { static: false }) previewGallery: NgxGalleryComponent;


  constructor(public mainTopImagesService: MainTopImagesService) {
    mainTopImagesService.galleryImages = [
      {
        small: '../../assets/media/images/lagen/terrasse.jpg',
        medium: '../../assets/media/images/lagen/terrasse.jpg',
        big: '../../assets/media/images/lagen/terrasse.jpg'
      },
      {
        small: '../../assets/media/images/lagen/herbst.jpg',
        medium: '../../assets/media/images/lagen/herbst.jpg',
        big: '../../assets/media/images/lagen/herbst.jpg',
      },
      {
        small: '../../assets/media/images/lagen/reben.jpg',
        medium: '../../assets/media/images/lagen/reben.jpg',
        big: '../../assets/media/images/lagen/reben.jpg',
      }
    ];
    mainTopImagesService.galleryImagesSm = [
      {
        small: '../../assets/media/images/lagen/terrasse_sm.jpg',
        medium: '../../assets/media/images/lagen/terrasse_sm.jpg',
        big: '../../assets/media/images/lagen/terrasse_sm.jpg',
      },
      {
        small: '../../assets/media/images/lagen/herbst_sm.jpg',
        medium: '../../assets/media/images/lagen/herbst_sm.jpg',
        big: '../../assets/media/images/lagen/herbst_sm.jpg',
      },
      {
        small: '../../assets/media/images/lagen/reben.jpg',
        medium: '../../assets/media/images/lagen/reben.jpg',
        big: '../../assets/media/images/lagen/reben.jpg',
      },
    ];
    mainTopImagesService.showCopyrightOWM = false;
    mainTopImagesService.showCopyright = false;

  }

  ngOnInit() {
    this.galleryOptions = [
      {
        width: '0px',
        height: '0px',
        image: false,
        thumbnails: false,
        imageArrows: false,
        thumbnailsArrows: false,
        imageAutoPlay: false,
        preview: false,
        previewArrows: false, 
        imageDescription:true

      }
    ];

    this.galleryImages = [
      {
        small: '../../assets/media/images/boden/js.png',
        medium: '../../assets/media/images/boden/js.png',
        big: '../../assets/media/images/boden/js.png',
        description: '© Josef Fischer'
      },
      {
        small: '../../assets/media/images/boden/fr.png',
        medium: '../../assets/media/images/boden/fr.png',
        big: '../../assets/media/images/boden/fr.png',
      },
      {
        small: '../../assets/media/images/boden/kr.png',
        medium: '../../assets/media/images/boden/kr.png',
        big: '../../assets/media/images/boden/kr.png',
      }
    ];
  }

  showGalleryImageAtIndex(index: number) {
    this.previewGallery.openPreview(index);
  }

}
