import { Category } from './entities/category';
import { WineItem } from './entities/wine';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../environments/environment';
import { DealerCountry } from './entities/dealer-country';


@Injectable({
  providedIn: 'root'
})
export class DealerService {

  private url = environment.apiUrl;
  constructor(private http: HttpClient) { }

  getDealerCountries(): Observable<DealerCountry[]> {
    console.log("delaer");
    return this.http.get<DealerCountry[]>('assets/data/countries.json');
  }

}
