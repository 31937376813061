import { Reward } from './entities/reward';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../environments/environment';
import { Pricer } from './entities/pricer';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RewardService {

  private url = environment.apiUrl;
  constructor(private http: HttpClient) { }

  getRewards(): Observable<Reward[]> {
    return this.http.get<Reward[]>(this.url + '/awards');
  }

  getPricers(): Observable<Pricer[]> {
    return this.http.get<Pricer[]>(this.url + '/assets/data/pricers.json')
      .pipe(
        map(data => {
          for (let d of data) {
            d.awards = d.awards.sort((n1, n2) => {
              if (n1.year < n2.year) {
                return 1;
              }

              if (n1.year > n2.year) {
                return -1;
              }

              return 0;
            })
          }
          return data;
        })
      );
  }
}
