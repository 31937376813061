import { Component, OnInit, ChangeDetectorRef, ViewChild, AfterViewChecked, AfterViewInit, Input } from '@angular/core';
import { NguCarousel, NguCarouselStore, NguCarouselConfig } from '@ngu/carousel';
import { WineItem } from '../entities/wine';

@Component({
  selector: 'app-wine-carousel-v2',
  templateUrl: './wine-carousel-v2.component.html',
  styleUrls: ['./wine-carousel-v2.component.scss']
})
export class WineCarouselV2Component implements AfterViewInit {

  name = 'Angular';
  slideNo = 0;
  withAnim = true;
  resetAnim = true;
  @Input() wines: WineItem[];


  @ViewChild('myCarousel', {static:false}) myCarousel: NguCarousel<any>;
  carouselConfig: NguCarouselConfig = {
    grid: { xs: 1, sm: 2, md: 2, lg: 3, all: 0 },
    load: 3,
    loop: true,
    touch: true,
    velocity: 0.2,
    point: {
      visible: true,
      hideOnSingleSlide: true
    }
  }

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  reset() {
    this.myCarousel.reset(!this.resetAnim);
  }

  moveTo(slide) {
    this.myCarousel.moveTo(slide, !this.withAnim);
  }
}