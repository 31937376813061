import { Injectable } from '@angular/core';
import { ContactMessage } from './entities/contactMessage';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EmailSenderService {

  private url = environment.slackUrl;
  constructor(private http: HttpClient) { }

  sendEmail(contactMessage: ContactMessage) {
    return this.http.post<any>(this.url + '/slack/send.php', contactMessage);
    //return this.http.post<any>('http://127.0.0.1:8080/send.php', contactMessage);
  }

}
