import { Component, OnInit, Input, HostListener } from '@angular/core';
import { WineItem } from '../entities/wine';
import { NgxHmCarouselBreakPointUp } from 'ngx-hm-carousel';

enum ScreenSize { XS, SM, MD, LG, XL }


@Component({
  selector: 'app-wine-carousel',
  templateUrl: './wine-carousel.component.html',
  styleUrls: ['./wine-carousel.component.scss']
})
export class WineCarouselComponent implements OnInit {

  @Input() wines: WineItem[];
  @Input() autoplay = false;

  private screenSize: ScreenSize;


  index = 0;
  speed = 5000;
  infinite = true;
  direction = 'right';
  directionToggle = true;
  showNum = 3;
  isIEOrEdge: boolean;
  constructor() { }

  ngOnInit() {
    this.getScreenSize();
    this.updateNumberOfCarousel();
  }

  public getScreenSize() {
    const WIDTH = window.innerWidth;
    if (WIDTH < 600) {
      this.screenSize = ScreenSize.XS;
    } else if (WIDTH < 960) {
      this.screenSize = ScreenSize.SM;
    } else if (WIDTH < 1279) {
      this.screenSize = ScreenSize.MD;
    } else if (WIDTH < 1919) {
      this.screenSize = ScreenSize.LG;
    } else if (WIDTH < 5000) {
      this.screenSize = ScreenSize.XL;
    }
  }

  private updateNumberOfCarousel() {
    if (this.screenSize === ScreenSize.XS) {
      this.showNum = 1;
    } else if (this.screenSize === ScreenSize.SM) {
      this.showNum = 2;
    } else if (this.screenSize === ScreenSize.MD || this.screenSize === ScreenSize.LG) {
      this.showNum = 3;
    } else if (this.screenSize > ScreenSize.LG) {
      this.showNum = 4;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.getScreenSize();
    this.updateNumberOfCarousel();
  }

}
