import { WineService } from './../wine.service';
import { Category } from './../entities/category';
import { Component, OnInit } from '@angular/core';
import { MainTopImagesService } from '../main-top-images.service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

  categories: Category[];


  constructor(private wineService: WineService, public mainTopImagesService: MainTopImagesService) 
  {
    mainTopImagesService.galleryImages = [
      {
        small: '../../assets/media/images/produkte/keller_komp_org.jpg',
        medium: '../../assets/media/images/produkte/keller_komp_org.jpg',
        big: '../../assets/media/images/produkte/keller_komp_org.jpg'

      },
      {
        small: '../../assets/media/images/produkte/keller_org.jpg',
        medium: '../../assets/media/images/produkte/keller_org.jpg',
        big: '../../assets/media/images/produkte/keller_org.jpg'


      }
    ];
    mainTopImagesService.galleryImagesSm = [
      {
        small: '../../assets/media/images/produkte/keller_komp_sm_org.jpg',
        medium: '../../assets/media/images/produkte/keller_komp_sm_org.jpg',
        big: '../../assets/media/images/produkte/keller_komp_sm_org.jpg',

      },
      {
        small: '../../assets/media/images/produkte/keller_sm_org.jpg',
        medium: '../../assets/media/images/produkte/keller_sm_org.jpg',
        big: '../../assets/media/images/produkte/keller_sm_org.jpg',


      }
    ];
    mainTopImagesService.showCopyright = false;
    mainTopImagesService.showCopyrightOWM = false;

  }

  ngOnInit() {

    this.getCategories();
  }

  getCategories(): void {
    this.wineService.getCategories()
      .subscribe(
        data => {
          this.categories = [];
          data.forEach(element => {
            if(element.wines.length > 0)
             // element.wines.forEach(e => {
               // e.image.url = "/assets/data/images" + e.image.url;
              //});
              this.categories.push(element);
          });
        },
        error => {
          console.log(error);
        }
      );
  }

}
