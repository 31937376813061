import { Component, OnInit, AfterViewInit, ViewChild, ElementRef} from '@angular/core';
import { ContactMessage } from '../entities/contactMessage';
import { MatSnackBar } from '@angular/material';
import { EmailSenderService } from '../email-sender.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MainTopImagesService } from '../main-top-images.service';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit, AfterViewInit {

  @ViewChild('mapContainer', {static: false}) gmap: ElementRef;

  contactForm: FormGroup;


  constructor(private emailSenderService: EmailSenderService, private _snackBar: MatSnackBar, private formBuilder: FormBuilder, public mainTopImagesService: MainTopImagesService) {
    mainTopImagesService.showCopyright = true;
    mainTopImagesService.showCopyrightOWM = false;

    mainTopImagesService.galleryImages = [
      {
        small: '../../assets/media/images/kontakt/w11c.jpg',
        medium: '../../assets/media/images/kontakt/w11c.jpg',
        big: '../../assets/media/images/kontakt/w11c.jpg'
      }];
      mainTopImagesService.galleryImagesSm = [
        {
          small: '../../assets/media/images/kontakt/w11c-sm.jpg',
          medium: '../../assets/media/images/kontakt/w11c-sm.jpg',
          big: '../../assets/media/images/kontakt/w11c-sm.jpg'
        }];
      
    }
    
  ngOnInit() {
    this.contactForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      text: ['', Validators.required],
      cdk: ['', Validators.required]
    });
  }
  ngAfterViewInit() {
    this.mapInitializer();

  }

  get f() { return this.contactForm.controls; }

  mapInitializer() {
   }


  resolved($event) {
    console.log($event);
  }

  submitForm(formData: FormGroup) {
/*
    if (this.contactForm.valid && this.captcha_response) {
      this.emailSenderService.sendEmail(new ContactMessage(formData.value.email, formData.value.name, formData.value.text, this.captcha_response)).subscribe(
        data => {
          if (data.status == 1) {
            this.submitted = true;
            this._snackBar.open('Email wurde gesendet', 'OK', {
              duration: 2000,
            });
          }
          else {
            this._snackBar.open('Ups, da ist etwas schief gegangen', 'OK', {
              duration: 2000,
            });
          }
        },
        error => {
          console.log(error);
          this._snackBar.open('Ups, da ist etwas schief gegangen', 'OK', {
            duration: 2000,
          });
        }
      );
    } else {
      this._snackBar.open('Erfüllen Sie bitte alle Pflichtfelder', 'OK', {
        duration: 2000,
      });
    }*/
  }

}
