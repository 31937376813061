import { Category } from './entities/category';
import { WineItem } from './entities/wine';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class WineService {

  private url = environment.apiUrl;
  constructor(private http: HttpClient) { }

  getWinesRange(startIndex: number, amount: number): Observable<WineItem[]> {
    console.log(startIndex);
    //return this.http.get<WineItem[]>(this.url + `/wines?_start=${startIndex}&_limit=${amount}`);
    return this.http.get<WineItem[]>('/assets/data/some.json');
  }

  getWinesByCategorytest(categoryId: string): Observable<WineItem[]> {
    console.log(categoryId);
    return this.http.get<WineItem[]>(this.url + `/wines?category.name=${categoryId}`);
  }

  getCategories(): Observable<Category[]> {
    console.log("hier");
    return this.http.get<Category[]>('/assets/data/categories.json');
  }

}
