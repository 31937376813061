export class WineItem {
    name: string;
    description: string;
    image: {
        url: string;
    };
    alcohol: number;
    acid: number;
    year: number;
    category: {
        name: string
    }
}
